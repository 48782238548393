
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import Swal from "sweetalert2";
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { ErrorMessage, Field, Form } from "vee-validate";
import { VueEditor } from "vue3-editor";
import { DeleteInterviewModel } from "@/domain/interviewer/delete-interview/model/DeleteInterviewModel";
import { useRouter } from "vue-router";
import { dateToIsoDate } from "@/presentation/helper/DateFormatter";

export default defineComponent({
  name: "interview-dropdown",
  components: {
    Field,
    Form,
    ErrorMessage,
    VueEditor,
  },
  props: {
    interviewId: Number,
  },
  setup(props) {
    const router = useRouter();
    const today = dateToIsoDate(new Date())
    const startDate = ref(today);
    const endDate = ref("");
    const featureInput = ref("");
    const store = useStore();
    const interviewerController =
      store.state.ControllersModule.interviewerController;
    const swalNotification = new SwalNotification();
    const { t } = useI18n();

    // Silme işlemi
    const deleteInterview = (interviewId?: number) => {
      const interviewDeleteModel: DeleteInterviewModel = {
        id: interviewId,
      };

      Swal.fire({
        title: t("swalMessages.DELETE_INTERVIEW"),
        text: t("swalMessages.CANNOT_BE_UNDONE"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("common.button.delete"),
        cancelButtonText: t("common.button.cancel"),
        heightAuto: false,
      }).then((result) => {
        if (result.isConfirmed) {
          interviewerController
            .deleteInterview(interviewDeleteModel)
            .then((response) => {
              if (response.isSuccess) {
                Swal.fire({
                  title: t("swalMessages.SUCCESSFUL"),
                  text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_DELETE_INTERVIEW),
                  icon: "success",
                  heightAuto: false,
                }).then(() => {
                  router.push({ path: "/interviewer/jobposting-list" });
                });
              } else {
                swalNotification.error(
                  response.error.cause.cause +
                    t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                    response.error.code +
                    response.error.cause.code,
                  t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                );
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      });
    };

    onMounted(() => {});

    return {
      today,
      startDate,
      endDate,
      deleteInterview,
      featureInput,
    };
  },
});
